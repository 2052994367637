
.list-container{
	margin: 20px;
	height: 50vh;
	overflow-y: auto;
	overflow-x: hidden;
}
.ewModal{
	min-height: 50vh;
	width: 600px;
}