#text-container {
  position: relative;
}
.blink_me {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
#cursor {
  position: absolute;
  top: 0;
  right: -5px;
  height: 100%;
  width: 2px;
  background-color: #000;
  animation: blink 0.7s infinite;
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
