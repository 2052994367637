.ag-theme-alpine .ag-root-wrapper  {
    border: none;
    box-shadow: 50px;
}

.ag-theme-alpine .ag-icon-first::before {
    content: var(--ag-icon-font-code-first, "\f116");
}

.ag-theme-alpine .ag-icon-previous::before {
    content: var(--ag-icon-font-code-previous, "\f126");
}

.ag-theme-alpine .ag-icon-next::before {
    content: var(--ag-icon-font-code-next, "\f120");
}

.ag-theme-alpine .ag-icon-last::before {
    content: var(--ag-icon-font-code-next, "\f119");
}

.data-grid-header {
    background-color: white;
}
.pointer-row {
    cursor: pointer;
}