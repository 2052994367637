* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  /* .footer {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
  } */
  .footer{
      background-color:#F4F4F4;
      padding: 20px;
  }
  
  .footer .heading {
    max-width: 1010px;
    width: 90%;
    text-transform: uppercase;
    margin: 0 auto;
    margin-bottom: 3rem;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  }
  
  .footer .content {
    display: flex;
    justify-content: space-evenly;
    margin: 1.5rem;
  }
  
  .footer .content p {
    margin-bottom: 1.3rem;
    color: rgba(0, 0, 0, 0.829);
  }
  
  .footer .content a {
    text-decoration: none;
    color: rgb(0, 0, 0, 0.829);
  }
  
  .footer .content a:hover {
    border-bottom: 1px solid rgba(0, 0, 0, 0.829);
  }
  
  .footer .content h4 {
    margin-bottom: 1.3rem;
    font-size: 19px;
  }
  
  footer {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  footer hr {
    margin: 2rem 0;
  }
  
  @media (max-width: 767px) {
    .footer .content {
      display: flex;
      flex-direction: column;
      font-size: 14px;
    }
  
    .footer {
      position: unset;
    }
  }
  
  @media (min-width: 768px) and (max-width: 1024px) {
    .footer .content,
    .footer {
      font-size: 14px;
    }
  }
  
  @media (orientation: landscape) and (max-height: 500px) {
    .footer {
      position: unset;
    }
  }
  