:root {

    /* Reflects the divider variable from the theme object */
        --colorDivider: rgba(0,0,0,0.120);
        --colorRadioButtonColour: rgba(236,219,231,1);
        --colorPmsNavButtonSelected: rgba(250,244,252,1);
        --colorPmsNavTextSelected: rgba(88,15,65,1);
    
    /* Reflects the text.primary variable from the theme object */
        --colorTextPrimary: rgba(0,0,0,0.870);
    
    /* Reflects the text.secondary variable from the theme object */
        --colorTextSecondary: rgba(0,0,0,0.600);
    
    /* Reflects the text.disabled variable from the theme object */
        --colorTextDisabled: rgba(0,0,0,0.380);
    
    /* Reflects the primary.light variable from the theme object */
        --colorPrimaryLight: rgba(126,21,93,1);
    
    /* Reflects the primary.main variable from the theme object */
        --colorPrimaryMain: rgba(88,15,65,1);
    
    /* Used for hover states. Reflects the primary.dark variable from the theme object */
        --colorPrimaryDark: rgba(65,11,48,1);
    
    /* Reflects the primary.contrast variable from the theme object. Color that keeps a contrast ratio above AA when XX.main is used as a bg. color */
        --colorPrimaryContrast: rgba(255,255,255,1);
    
    /* Used for hover states. The token represents the value of action.hoverOpacity (0.04 by default) of the main token. */
        --colorPrimaryStatesHover: rgba(88,15,65,0.040);
    
    /* Used for selected states. The token represents the value of action.selectedOpacity (0.08 by default) of the main token. */
        --colorPrimaryStatesSelected: rgba(88,15,65,0.080);
    
    /* Used for focus states. The token represents the value of action.focusOpacity (0.12 by default) of the main token. */
        --colorPrimaryStatesFocus: rgba(88,15,65,0.120);
    
    /* Used for focus visible states. The token represents the value of focusVisibleOpacity (0.3 by default) of the main token. */
        --colorPrimaryStatesFocusVisible: rgba(88,15,65,0.300);
    
    /* Used for enabled states (e.g Button outlined variant). The token represents the value of outlinedBorderOpacity (0.5 by default) of the main token. */
        --colorPrimaryStatesOutlinedBorder: rgba(88,15,65,0.500);
    
    /* Reflects the secondary.light variable from the theme object */
        --colorSecondaryLight: rgba(97,56,105,1);
    
    /* Reflects the secondary.main variable from the theme object */
        --colorSecondaryMain: rgba(71,41,76,1);
    
    /* Used for hover states. Reflects the secondary.dark variable from the theme object */
        --colorSecondaryDark: rgba(55,32,59,1);
    
    /* Reflects the secondary.contrast variable from the theme object. Color that keeps a contrast ratio above AA when XX.main is used as a bg. color */
        --colorSecondaryContrast: rgba(255,255,255,1);
    
    /* Used for hover states. The token represents the value of action.hoverOpacity (0.04 by default) of the main token. */
        --colorSecondaryStatesHover: rgba(71,41,76,0.040);
    
    /* Used for selected states. The token represents the value of action.selectedOpacity (0.08 by default) of the main token. */
        --colorSecondaryStatesSelected: rgba(71,41,76,0.080);
    
    /* Used for focus visible states. The token represents 12% of the Secondary/Main token */
        --colorSecondaryStatesFocus: rgba(71,41,76,0.120);
    
    /* Used for focus visible states. The token represents the value of focusVisibleOpacity (0.3 by default) of the main token. */
        --colorSecondaryStatesFocusVisible: rgba(71,41,76,0.300);
    
    /* Used for enabled states (e.g Button outlined variant). The token represents the value of outlinedBorderOpacity (0.5 by default) of the main token. */
        --colorSecondaryStatesOutlinedBorder: rgba(71,41,76,0.500);
    
    /* Reflects the action.active variable from the theme object */
        --colorActionActive: rgba(0,0,0,0.560);
    
    /* Reflects the action.hover variable from the theme object */
        --colorActionHover: rgba(0,0,0,0.040);
    
    /* Reflects the action.selected variable from the theme object */
        --colorActionSelected: rgba(0,0,0,0.080);
    
    /* Reflects the action.disabledBackground variable from the theme object */
        --colorActionDisabledBackground: rgba(0,0,0,0.120);
    
    /* Reflects the action.focus variable from the theme object */
        --colorActionFocus: rgba(0,0,0,0.120);
    
    /* Reflects the action.disabled variable from the theme object */
        --colorActionDisabled: rgba(0,0,0,0.380);
    
    /* Used for hover states. Reflects the error.dark variable from the theme object */
        --colorErrorDark: rgba(227,7,7,1);
    
    /* Reflects the error.main variable from the theme object */
        --colorErrorMain: rgba(249,50,50,1);
    
    /* Reflects the error.light variable from the theme object */
        --colorErrorLight: rgba(249,75,75,1);
    
    /* Reflects the error.contrast variable from the theme object. Color that keeps a contrast ratio above AA when XX.main is used as a bg. color */
        --colorErrorContrast: rgba(255,255,255,1);
    
    /* Used for hover states. The token represents the value of action.hoverOpacity (0.04 by default) of the main token. */
        --colorErrorStatesHover: rgba(249,50,50,0.040);
    
    /* Used for selected states. The token represents the value of action.selectedOpacity (0.08 by default) of the main token. */
        --colorErrorStatesSelected: rgba(249,50,50,0.080);
    
    /* Used for focus visible states. The token represents the value of focusVisibleOpacity (0.3 by default) of the main token. */
        --colorErrorStatesFocusVisible: rgba(249,50,50,0.300);
    
    /* Used for enabled states (e.g Button outlined variant). The token represents the value of outlinedBorderOpacity (0.5 by default) of the main token. */
        --colorErrorStatesOutlinedBorder: rgba(249,50,50,0.500);
    
    /* Reflects the warning.light variable from the theme object */
        --colorWarningLight: rgba(242,143,50,1);
    
    /* Reflects the warning.main variable from the theme object */
        --colorWarningMain: rgba(240,129,26,1);
    
    /* Used for hover states. Reflects the warning.dark variable from the theme object */
        --colorWarningDark: rgba(222,114,14,1);
    
    /* Reflects the warning.contrast variable from the theme object. Color that keeps a contrast ratio above AA when XX.main is used as a bg. color */
        --colorWarningContrast: rgba(255,255,255,1);
    
    /* Used for hover states. The token represents the value of action.hoverOpacity (0.04 by default) of the main token. */
        --colorWarningStatesHovered: rgba(237,108,2,0.040);
    
    /* Used for selected states. The token represents the value of action.selectedOpacity (0.08 by default) of the main token. */
        --colorWarningStatesSelected: rgba(237,108,2,0.040);
    
    /* Used for focus visible states. The token represents the value of focusVisibleOpacity (0.3 by default) of the main token. */
        --colorWarningStatesFocusVisible: rgba(237,108,2,0.300);
    
    /* Used for enabled states (e.g Button outlined variant). The token represents the value of outlinedBorderOpacity (0.5 by default) of the main token. */
        --colorWarningStatesOutlinedBorder: rgba(237,108,2,0.500);
    
    /* Reflects the info.main variable from the theme object */
        --colorInfoMain: rgba(2,136,209,1);
    
    /* Used for hover states. Reflects the info.dark variable from the theme object */
        --colorInfoDark: rgba(1,87,155,1);
    
    /* Reflects the info.light variable from the theme object */
        --colorInfoLight: rgba(3,169,244,1);
    
    /* Reflects the info.contrast variable from the theme object. Color that keeps a contrast ratio above AA when XX.main is used as a bg. color */
        --colorInfoContrast: rgba(255,255,255,1);
    
    /* Used for hover states. The token represents the value of action.hoverOpacity (0.04 by default) of the main token. */
        --colorInfoStatesHover: rgba(2,136,209,0.040);
    
    /* Used for selected states. The token represents the value of action.selectedOpacity (0.08 by default) of the main token. */
        --colorInfoStatesSelected: rgba(2,136,209,0.080);
    
    /* Used for focus visible states. The token represents the value of focusVisibleOpacity (0.3 by default) of the main token. */
        --colorInfoStatesFocusVisible: rgba(2,136,209,0.300);
    
    /* Used for enabled states (e.g Button outlined variant). The token represents the value of outlinedBorderOpacity (0.5 by default) of the main token. */
        --colorInfoStatesOutlinedBorder: rgba(2,136,209,0.500);
    
    /* Reflects the success.light variable from the theme object */
        --colorSuccessLight: rgba(5,192,36,1);
    
    /* Reflects the success.main variable from the theme object */
        --colorSuccessMain: rgba(4,153,28,1);
    
    /* Used for hover states. Reflects the success.dark variable from the theme object */
        --colorSuccessDark: rgba(3,120,23,1);
    
    /* Reflects the success.contrast variable from the theme object. Color that keeps a contrast ratio above AA when XX.main is used as a bg. color */
        --colorSuccessContrast: rgba(255,255,255,1);
    
    /* Used for hover states. The token represents the value of action.hoverOpacity (0.04 by default) of the main token. */
        --colorSuccessStatesHover: rgba(4,153,28,0.040);
    
    /* Used for selected states. The token represents the value of action.selectedOpacity (0.08 by default) of the main token. */
        --colorSuccessStatesSelected: rgba(4,153,28,0.080);
    
    /* Used for focus visible states. The token represents the value of focusVisibleOpacity (0.3 by default) of the main token. */
        --colorSuccessStatesFocusVisible: rgba(4,153,28,0.300);
    
    /* Used for enabled states (e.g Button outlined variant). The token represents the value of outlinedBorderOpacity (0.5 by default) of the main token. */
        --colorSuccessStatesOutlinedBorder: rgba(4,153,28,0.500);
    
    /* Reflects the background.default variable from the theme object */
        --colorBackgroundDefault: rgba(255,255,255,1);
    
    /* Reflects the background.paper variable from the theme object */
        --colorBackgroundPaperElevation0: rgba(255,255,255,1);
        --colorBackgroundPaperElevation1: rgba(255,255,255,1);
        --colorBackgroundPaperElevation2: rgba(255,255,255,1);
        --colorBackgroundPaperElevation3: rgba(255,255,255,1);
        --colorBackgroundPaperElevation4: rgba(255,255,255,1);
        --colorBackgroundPaperElevation5: rgba(255,255,255,1);
        --colorBackgroundPaperElevation6: rgba(255,255,255,1);
        --colorBackgroundPaperElevation7: rgba(255,255,255,1);
        --colorBackgroundPaperElevation8: rgba(255,255,255,1);
        --colorBackgroundPaperElevation9: rgba(255,255,255,1);
        --colorBackgroundPaperElevation10: rgba(255,255,255,1);
        --colorBackgroundPaperElevation11: rgba(255,255,255,1);
        --colorBackgroundPaperElevation12: rgba(255,255,255,1);
        --colorBackgroundPaperElevation13: rgba(255,255,255,1);
        --colorBackgroundPaperElevation14: rgba(255,255,255,1);
        --colorBackgroundPaperElevation15: rgba(255,255,255,1);
        --colorBackgroundPaperElevation16: rgba(255,255,255,1);
        --colorBackgroundPaperElevation17: rgba(255,255,255,1);
        --colorBackgroundPaperElevation18: rgba(255,255,255,1);
        --colorBackgroundPaperElevation19: rgba(255,255,255,1);
        --colorBackgroundPaperElevation20: rgba(255,255,255,1);
        --colorBackgroundPaperElevation21: rgba(255,255,255,1);
        --colorBackgroundPaperElevation22: rgba(255,255,255,1);
        --colorBackgroundPaperElevation23: rgba(255,255,255,1);
        --colorBackgroundPaperElevation24: rgba(255,255,255,1);
        --colorInheritWhiteMain: rgba(255,255,255,1);
    
    /* Used for hover states. The token represents the value of action.hoverOpacity (0.04 by default) of the main token. */
        --colorInheritWhiteHover: rgba(255,255,255,0.040);
    
    /* Used for selected states. The token represents the value of action.selectedOpacity (0.08 by default) of the main token. */
        --colorInheritWhiteSelected: rgba(255,255,255,0.080);
    
    /* Used for focus states. The token represents the value of action.focusOpacity (0.12 by default) of the main token. */
        --colorInheritWhiteFocus: rgba(255,255,255,0.120);
    
    /* Used for focus visible states. The token represents the value of focusVisibleOpacity (0.3 by default) of the main token. */
        --colorInheritWhiteFocusVisible: rgba(255,255,255,0.300);
        --colorInheritTextPrimaryMain: rgba(0,0,0,0.870);
    
    /* Used for hover states. The token represents the value of action.hoverOpacity (0.04 by default) of the main token. */
        --colorInheritTextPrimaryHover: rgba(0,0,0,0.040);
    
    /* Used for selected states. The token represents the value of action.selectedOpacity (0.08 by default) of the main token. */
        --colorInheritTextPrimarySelected: rgba(0,0,0,0.080);
    
    /* Used for focus states. The token represents the value of action.focusOpacity (0.12 by default) of the main token. */
        --colorInheritTextPrimaryFocus: rgba(0,0,0,0.120);
    
    /* Used for focus visible states. The token represents the value of focusVisibleOpacity (0.3 by default) of the main token. */
        --colorInheritTextPrimaryFocusVisible: rgba(0,0,0,0.300);
        --colorGrey50: rgba(250,250,250,1);
        --colorGrey100: rgba(245,245,245,1);
        --colorGrey200: rgba(238,238,238,1);
        --colorGrey300: rgba(224,224,224,1);
        --colorGrey400: rgba(189,189,189,1);
        --colorGrey500: rgba(158,158,158,1);
        --colorGrey600: rgba(117,117,117,1);
        --colorGrey700: rgba(97,97,97,1);
        --colorGrey800: rgba(66,66,66,1);
        --colorGrey900: rgba(33,33,33,1);
        --colorGreyA100: rgba(213,213,213,1);
        --colorGreyA200: rgba(170,170,170,1);
        --colorGreyA400: rgba(97,97,97,1);
        --colorGreyA700: rgba(48,48,48,1);
        --colorIndigo50: rgba(232,234,246,1);
        --colorIndigo100: rgba(197,202,233,1);
        --colorIndigo200: rgba(159,168,218,1);
        --colorIndigo300: rgba(121,134,203,1);
        --colorIndigo400: rgba(92,107,192,1);
        --colorIndigo500: rgba(63,81,181,1);
        --colorIndigo600: rgba(57,73,171,1);
        --colorIndigo700: rgba(48,63,159,1);
        --colorIndigo800: rgba(40,53,147,1);
        --colorIndigo900: rgba(26,35,126,1);
        --colorIndigoA100: rgba(140,158,255,1);
        --colorIndigoA200: rgba(83,109,254,1);
        --colorIndigoA400: rgba(61,90,254,1);
        --colorIndigoA700: rgba(48,79,254,1);
        --colorDeepPurple50: rgba(237,231,246,1);
        --colorDeepPurple100: rgba(209,196,233,1);
        --colorDeepPurple200: rgba(179,157,219,1);
        --colorDeepPurple300: rgba(149,117,205,1);
        --colorDeepPurple400: rgba(126,87,194,1);
        --colorDeepPurple500: rgba(103,58,183,1);
        --colorDeepPurple600: rgba(94,53,177,1);
        --colorDeepPurple700: rgba(81,45,168,1);
        --colorDeepPurple800: rgba(69,39,160,1);
        --colorDeepPurple900: rgba(49,27,146,1);
        --colorDeepPurpleA100: rgba(179,136,255,1);
        --colorDeepPurpleA200: rgba(124,77,255,1);
        --colorDeepPurpleA400: rgba(101,31,255,1);
        --colorDeepPurpleA700: rgba(98,0,234,1);
        --colorAmber50: rgba(255,248,225,1);
        --colorAmber100: rgba(255,236,179,1);
        --colorAmber200: rgba(255,224,130,1);
        --colorAmber300: rgba(255,213,79,1);
        --colorAmber400: rgba(255,202,40,1);
        --colorAmber500: rgba(255,193,7,1);
        --colorAmber600: rgba(255,179,0,1);
        --colorAmber700: rgba(255,160,0,1);
        --colorAmber800: rgba(255,143,0,1);
        --colorAmber900: rgba(255,111,0,1);
        --colorAmberA100: rgba(255,229,127,1);
        --colorAmberA200: rgba(255,215,64,1);
        --colorAmberA400: rgba(255,196,0,1);
        --colorAmberA700: rgba(255,171,0,1);
        --colorOrange50: rgba(255,243,224,1);
        --colorOrange100: rgba(255,224,178,1);
        --colorOrange200: rgba(255,204,128,1);
        --colorOrange300: rgba(255,183,77,1);
        --colorOrange400: rgba(255,167,38,1);
        --colorOrange500: rgba(255,152,0,1);
        --colorOrange600: rgba(251,140,0,1);
        --colorOrange700: rgba(245,124,0,1);
        --colorOrange800: rgba(239,108,0,1);
        --colorOrange900: rgba(230,81,0,1);
        --colorOrangeA100: rgba(255,209,128,1);
        --colorOrangeA200: rgba(255,171,64,1);
        --colorOrangeA400: rgba(255,145,0,1);
        --colorOrangeA700: rgba(255,109,0,1);
        --colorPink50: rgba(252,228,236,1);
        --colorPink100: rgba(248,187,208,1);
        --colorPink200: rgba(244,143,177,1);
        --colorPink300: rgba(240,98,146,1);
        --colorPink400: rgba(236,64,122,1);
        --colorPink500: rgba(233,30,99,1);
        --colorPink600: rgba(216,27,96,1);
        --colorPink700: rgba(194,24,91,1);
        --colorPink800: rgba(173,20,87,1);
        --colorPink900: rgba(136,14,79,1);
        --colorPinkA100: rgba(255,128,171,1);
        --colorPinkA200: rgba(255,64,129,1);
        --colorPinkA400: rgba(245,0,87,1);
        --colorPinkA700: rgba(197,17,98,1);
        --colorDeepOrange50: rgba(251,233,231,1);
        --colorDeepOrange100: rgba(255,204,188,1);
        --colorDeepOrange200: rgba(255,171,145,1);
        --colorDeepOrange300: rgba(255,138,101,1);
        --colorDeepOrange400: rgba(255,112,67,1);
        --colorDeepOrange500: rgba(255,87,34,1);
        --colorDeepOrange600: rgba(244,81,30,1);
        --colorDeepOrange700: rgba(230,74,25,1);
        --colorDeepOrange800: rgba(216,67,21,1);
        --colorDeepOrange900: rgba(191,54,12,1);
        --colorDeepOrangeA100: rgba(255,158,128,1);
        --colorDeepOrangeA200: rgba(255,110,64,1);
        --colorDeepOrangeA400: rgba(255,61,0,1);
        --colorDeepOrangeA700: rgba(221,44,0,1);
        --colorGreen50: rgba(232,245,233,1);
        --colorGreen100: rgba(200,230,201,1);
        --colorGreen200: rgba(165,214,167,1);
        --colorGreen300: rgba(129,199,132,1);
        --colorGreen400: rgba(102,187,106,1);
        --colorGreen500: rgba(76,175,80,1);
        --colorGreen600: rgba(67,160,71,1);
        --colorGreen700: rgba(56,142,60,1);
        --colorGreen800: rgba(46,125,50,1);
        --colorGreen900: rgba(27,94,32,1);
        --colorGreenA100: rgba(185,246,202,1);
        --colorGreenA200: rgba(105,240,174,1);
        --colorGreenA400: rgba(0,230,118,1);
        --colorGreenA700: rgba(0,200,83,1);
        --colorRed50: rgba(254,235,238,1);
        --colorRed100: rgba(254,205,210,1);
        --colorRed200: rgba(239,154,154,1);
        --colorRed300: rgba(229,115,115,1);
        --colorRed400: rgba(239,83,80,1);
        --colorRed500: rgba(244,67,54,1);
        --colorRed600: rgba(229,57,53,1);
        --colorRed700: rgba(211,47,47,1);
        --colorRed800: rgba(198,40,40,1);
        --colorRed900: rgba(183,28,28,1);
        --colorRedA100: rgba(255,138,128,1);
        --colorRedA200: rgba(255,82,82,1);
        --colorRedA400: rgba(255,23,68,1);
        --colorRedA700: rgba(213,0,0,1);
        --colorLightGreen50: rgba(241,248,233,1);
        --colorLightGreen100: rgba(220,237,200,1);
        --colorLightGreen200: rgba(197,225,165,1);
        --colorLightGreen300: rgba(174,213,129,1);
        --colorLightGreen400: rgba(139,195,74,1);
        --colorLightGreen500: rgba(139,195,74,1);
        --colorLightGreen600: rgba(124,179,66,1);
        --colorLightGreen700: rgba(104,159,56,1);
        --colorLightGreen800: rgba(85,139,47,1);
        --colorLightGreen900: rgba(51,105,30,1);
        --colorLightGreenA100: rgba(204,255,144,1);
        --colorLightGreenA200: rgba(178,255,89,1);
        --colorLightGreenA400: rgba(118,255,3,1);
        --colorLightGreenA700: rgba(100,221,23,1);
        --colorPurple50: rgba(243,229,245,1);
        --colorPurple100: rgba(225,190,231,1);
        --colorPurple200: rgba(206,147,216,1);
        --colorPurple300: rgba(186,104,200,1);
        --colorPurple400: rgba(171,71,188,1);
        --colorPurple500: rgba(156,39,176,1);
        --colorPurple600: rgba(142,36,170,1);
        --colorPurple700: rgba(123,31,162,1);
        --colorPurple800: rgba(106,27,154,1);
        --colorPurple900: rgba(74,20,140,1);
        --colorPurpleA100: rgba(234,128,252,1);
        --colorPurpleA200: rgba(224,64,251,1);
        --colorPurpleA400: rgba(213,0,249,1);
        --colorPurpleA700: rgba(170,0,255,1);
        --colorLime50: rgba(249,251,231,1);
        --colorLime100: rgba(240,244,195,1);
        --colorLime200: rgba(230,238,156,1);
        --colorLime300: rgba(220,231,117,1);
        --colorLime400: rgba(212,225,87,1);
        --colorLime500: rgba(205,220,57,1);
        --colorLime600: rgba(192,202,51,1);
        --colorLime700: rgba(175,180,43,1);
        --colorLime800: rgba(158,157,36,1);
        --colorLime900: rgba(130,119,23,1);
        --colorLimeA100: rgba(244,255,129,1);
        --colorLimeA200: rgba(238,255,65,1);
        --colorLimeA400: rgba(198,255,0,1);
        --colorLimeA700: rgba(174,234,0,1);
        --colorLightBlue50: rgba(225,245,254,1);
        --colorLightBlue100: rgba(179,229,252,1);
        --colorLightBlue200: rgba(129,212,250,1);
        --colorLightBlue300: rgba(79,195,247,1);
        --colorLightBlue400: rgba(41,182,246,1);
        --colorLightBlue500: rgba(3,169,244,1);
        --colorLightBlue600: rgba(3,155,229,1);
        --colorLightBlue700: rgba(2,136,209,1);
        --colorLightBlue800: rgba(2,119,189,1);
        --colorLightBlue900: rgba(1,87,155,1);
        --colorLightBlueA100: rgba(128,216,255,1);
        --colorLightBlueA200: rgba(64,196,255,1);
        --colorLightBlueA400: rgba(0,176,255,1);
        --colorLightBlueA700: rgba(0,145,234,1);
        --colorYellow50: rgba(255,253,231,1);
        --colorYellow100: rgba(255,249,196,1);
        --colorYellow200: rgba(255,245,157,1);
        --colorYellow300: rgba(255,241,118,1);
        --colorYellow400: rgba(255,238,88,1);
        --colorYellow500: rgba(255,235,59,1);
        --colorYellow600: rgba(253,216,53,1);
        --colorYellow700: rgba(251,192,45,1);
        --colorYellow800: rgba(249,168,37,1);
        --colorYellow900: rgba(245,127,23,1);
        --colorYellowA100: rgba(255,255,141,1);
        --colorYellowA200: rgba(255,255,0,1);
        --colorYellowA400: rgba(255,234,0,1);
        --colorYellowA700: rgba(255,214,0,1);
        --colorCyan50: rgba(224,247,250,1);
        --colorCyan100: rgba(178,235,242,1);
        --colorCyan200: rgba(128,222,234,1);
        --colorCyan300: rgba(77,208,225,1);
        --colorCyan400: rgba(38,198,218,1);
        --colorCyan500: rgba(0,188,212,1);
        --colorCyan600: rgba(0,172,193,1);
        --colorCyan700: rgba(0,151,167,1);
        --colorCyan800: rgba(0,131,143,1);
        --colorCyan900: rgba(0,96,100,1);
        --colorCyanA100: rgba(132,255,255,1);
        --colorCyanA200: rgba(24,255,255,1);
        --colorCyanA400: rgba(0,229,255,1);
        --colorCyanA700: rgba(0,184,212,1);
        --colorTeal50: rgba(224,242,241,1);
        --colorTeal100: rgba(178,223,219,1);
        --colorTeal200: rgba(128,203,196,1);
        --colorTeal300: rgba(77,182,172,1);
        --colorTeal400: rgba(38,166,154,1);
        --colorTeal500: rgba(0,150,136,1);
        --colorTeal600: rgba(0,137,123,1);
        --colorTeal700: rgba(0,121,107,1);
        --colorTeal800: rgba(0,105,92,1);
        --colorTeal900: rgba(0,77,64,1);
        --colorTealA100: rgba(167,255,235,1);
        --colorTealA200: rgba(100,255,218,1);
        --colorTealA400: rgba(29,233,182,1);
        --colorTealA700: rgba(0,191,165,1);
        --colorBlue50: rgba(227,242,253,1);
        --colorBlue100: rgba(187,222,251,1);
        --colorBlue200: rgba(144,202,249,1);
        --colorBlue300: rgba(255,255,255,0.300);
        --colorBlue400: rgba(66,165,245,1);
        --colorBlue500: rgba(33,150,243,1);
        --colorBlue600: rgba(30,136,229,1);
        --colorBlue700: rgba(0,87,178,1);
        --colorBlue800: rgba(21,101,192,1);
        --colorBlue900: rgba(13,71,161,1);
        --colorBlueA100: rgba(130,177,255,1);
        --colorBlueA200: rgba(68,138,255,1);
        --colorBlueA400: rgba(41,121,255,1);
        --colorBlueA700: rgba(41,98,255,1);
        --colorBlueGrey50: rgba(236,239,241,1);
        --colorBlueGrey100: rgba(207,216,220,1);
        --colorBlueGrey200: rgba(176,190,197,1);
        --colorBlueGrey300: rgba(144,164,174,1);
        --colorBlueGrey400: rgba(120,144,156,1);
        --colorBlueGrey500: rgba(96,125,139,1);
        --colorBlueGrey600: rgba(84,110,122,1);
        --colorBlueGrey700: rgba(69,90,100,1);
        --colorBlueGrey800: rgba(55,71,79,1);
        --colorBlueGrey900: rgba(38,50,56,1);
    
    /* Used for Alert content. The token represents 60% #000 on top of the Error/Main token */
        --colorAlertErrorContent: rgba(95,33,32,1);
    
    /* Used for Alert background. The token represents 90% #FFF on top of the Error/Main token */
        --colorAlertErrorFill: rgba(253,237,237,1);
    
    /* Used for Alert content. The token represents 60% #000 on top of the Warning/Main token */
        --colorAlertWarningContent: rgba(102,60,0,1);
    
    /* Used for Alert background. The token represents 90% #FFF on top of the Warning/Main token */
        --colorAlertWarningFill: rgba(255,244,229,1);
    
    /* Used for Alert content. The token represents 60% #000 on top of the Info/Main token */
        --colorAlertInfoContent: rgba(1,67,97,1);
    
    /* Used for Alert background. The token represents 90% #FFF on top of the Info/Main token */
        --colorAlertInfoFill: rgba(229,246,253,1);
    
    /* Used for Alert content. The token represents 60% #000 on top of the Success/Main token */
        --colorAlertSuccessContent: rgba(30,70,32,1);
    
    /* Used for Alert background. The token represents 90% #FFF on top of the Success/Main token */
        --colorAlertSuccessFill: rgba(237,247,237,1);
        --colorAppbarDefaultFill: rgba(245,245,245,1);
        --colorAvatarFill: rgba(189,189,189,1);
        --colorBackdropFill: rgba(0,0,0,0.500);
        --colorBreadcrumbsCollapseFill: rgba(245,245,245,1);
        --colorChipDefaultCloseFill: rgba(0,0,0,1);
        --colorChipDefaultHoverFill: rgba(0,0,0,0.120);
        --colorChipDefaultFocusFill: rgba(255,255,255,0.200);
        --colorChipDefaultEnabledBorder: rgba(189,189,189,1);
        --colorInputStandardEnabledBorder: rgba(0,0,0,0.420);
        --colorInputStandardHoverBorder: rgba(0,0,0,1);
        --colorInputFilledEnabledFill: rgba(0,0,0,0.060);
        --colorInputFilledHoverFill: rgba(0,0,0,0.090);
        --colorInputOutlinedEnabledBorder: rgba(0,0,0,0.230);
        --colorInputOutlinedHoverBorder: rgba(0,0,0,1);
        --colorRatingEnabledBorder: rgba(0,0,0,0.230);
        --colorRatingActiveFill: rgba(255,180,0,1);
        --colorSwitchKnobFill: rgba(250,250,250,1);
        --colorSwitchSlideFill: rgba(0,0,0,1);
        --colorTooltipFill: rgba(97,97,97,0.900);
        --colorSnackbarDefaultFill: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 100%);
    }
    .textStyleGroupCardLabel {
        font-size: 0.625rem;
        font-family: "Open Sans";
        font-weight: 300;
        font-style: normal;
        line-height: 14px;
        text-decoration: none;
        text-transform: none;
    }
    
    /* KRA */
    .textStyleH7 {
        font-size: 0.938rem;
        font-family: "Poppins";
        font-weight: 500;
        font-style: normal;
        line-height: 121%;
        text-decoration: none;
        text-transform: none;
    }
    
    /* KPI */
    .textStyleH8 {
        font-size: 1rem;
        font-family: "Poppins";
        font-weight: 400;
        font-style: normal;
        line-height: 116.68%;
        text-decoration: none;
        text-transform: none;
    }
    .textStyleTypographyH1 {
        font-size: 6rem;
        font-family: "Poppins";
        font-weight: 300;
        font-style: normal;
        line-height: 116.7%;
        letter-spacing: -1.5px;
        text-decoration: none;
        text-transform: none;
    }
    .textStyleTypographyH2 {
        font-size: 3.75rem;
        font-family: "Poppins";
        font-weight: 300;
        font-style: normal;
        line-height: 120%;
        letter-spacing: -0.5px;
        text-decoration: none;
        text-transform: none;
    }
    .textStyleTypographyH3 {
        font-size: 3rem;
        font-family: "Poppins";
        font-weight: 400;
        font-style: normal;
        line-height: 116.7%;
        text-decoration: none;
        text-transform: none;
    }
    .textStyleTypographyH4 {
        font-size: 2.125rem;
        font-family: "Poppins";
        font-weight: 400;
        font-style: normal;
        line-height: 123.5%;
        letter-spacing: 0.25px;
        text-decoration: none;
        text-transform: none;
    }
    .textStyleTypographyH5 {
        font-size: 1.5rem;
        font-family: "Poppins";
        font-weight: 400;
        font-style: normal;
        line-height: 133.4%;
        text-decoration: none;
        text-transform: none;
    }
    .textStyleTypographyH6 {
        font-size: 1.25rem;
        font-family: "Poppins";
        font-weight: 500;
        font-style: normal;
        line-height: 160%;
        letter-spacing: 0.15px;
        text-decoration: none;
        text-transform: none;
    }
    .textStyleTypographyBody1 {
        font-size: 1rem;
        font-family: "Open Sans";
        font-weight: 400;
        font-style: normal;
        line-height: 150%;
        letter-spacing: 0.15px;
        text-decoration: none;
        text-transform: none;
    }
    .textStyleTypographyBody2 {
        font-size: 0.875rem;
        font-family: "Open Sans";
        font-weight: 400;
        font-style: normal;
        line-height: 143%;
        letter-spacing: 0.17px;
        text-decoration: none;
        text-transform: none;
    }
    .textStyleTypographySubtitle1 {
        font-size: 1rem;
        font-family: "Open Sans";
        font-weight: 400;
        font-style: normal;
        line-height: 175%;
        letter-spacing: 0.15px;
        text-decoration: none;
        text-transform: none;
    }
    .textStyleTypographySubtitle2 {
        font-size: 0.875rem;
        font-family: "Open Sans";
        font-weight: 600;
        font-style: normal;
        line-height: 157%;
        letter-spacing: 0.1px;
        text-decoration: none;
        text-transform: none;
    }
    .textStyleTypographyOverline {
        font-size: 0.75rem;
        font-family: "Open Sans";
        font-weight: 400;
        font-style: normal;
        line-height: 266%;
        letter-spacing: 1px;
        text-decoration: none;
        text-transform: capitalize;
    }
    .textStyleTypographyCaption {
        font-size: 0.75rem;
        font-family: "Poppins";
        font-weight: 400;
        font-style: normal;
        line-height: 166%;
        letter-spacing: 0.4px;
        text-decoration: none;
        text-transform: none;
    }
    .textStyleComponentsAlertTitle {
        font-size: 1rem;
        font-family: "Open Sans";
        font-weight: 600;
        font-style: normal;
        line-height: 150%;
        letter-spacing: 0.15px;
        text-decoration: none;
        text-transform: none;
    }
    .textStyleComponentsAlertDescription {
        font-size: 0.875rem;
        font-family: "Open Sans";
        font-weight: 600;
        font-style: normal;
        line-height: 143%;
        letter-spacing: 0.15px;
        text-decoration: none;
        text-transform: none;
    }
    .textStyleComponentsAvatarInitials {
        font-size: 1.25rem;
        font-family: "Open Sans";
        font-weight: 400;
        font-style: normal;
        line-height: 20px;
        letter-spacing: 0.14px;
        text-decoration: none;
        text-transform: none;
    }
    .textStyleComponentsBadgeLabel {
        font-size: 0.75rem;
        font-family: "Open Sans";
        font-weight: 600;
        font-style: normal;
        line-height: 20px;
        letter-spacing: 0.14px;
        text-decoration: none;
        text-transform: none;
    }
    .textStyleComponentsButtonLarge {
        font-size: 0.938rem;
        font-family: "Poppins";
        font-weight: 500;
        font-style: normal;
        line-height: 26px;
        letter-spacing: 0.46px;
        text-decoration: none;
        text-transform: uppercase;
    }
    .textStyleComponentsButtonMedium {
        font-size: 0.875rem;
        font-family: "Poppins";
        font-weight: 500;
        font-style: normal;
        line-height: 24px;
        letter-spacing: 0.4px;
        text-decoration: none;
        text-transform: uppercase;
    }
    .textStyleComponentsButtonSmall {
        font-size: 0.812rem;
        font-family: "Poppins";
        font-weight: 500;
        font-style: normal;
        line-height: 22px;
        letter-spacing: 0.46px;
        text-decoration: none;
        text-transform: uppercase;
    }
    .textStyleComponentsInputLabel {
        font-size: 0.75rem;
        font-family: "Open Sans";
        font-weight: 400;
        font-style: normal;
        line-height: 12px;
        letter-spacing: 0.15px;
        text-decoration: none;
        text-transform: none;
    }
    .textStyleComponentsHelperText {
        font-size: 0.75rem;
        font-family: "Open Sans";
        font-weight: 400;
        font-style: normal;
        line-height: 166%;
        letter-spacing: 0.4px;
        text-decoration: none;
        text-transform: none;
    }
    .textStyleComponentsInputText {
        font-size: 1rem;
        font-family: "Open Sans";
        font-weight: 400;
        font-style: normal;
        line-height: 24px;
        letter-spacing: 0.15px;
        text-decoration: none;
        text-transform: none;
    }
    .textStyleComponentsChipLabel {
        font-size: 0.812rem;
        font-family: "Open Sans";
        font-weight: 400;
        font-style: normal;
        line-height: 18px;
        letter-spacing: 0.16px;
        text-decoration: none;
        text-transform: none;
    }
    .textStyleComponentsTooltipLabel {
        font-size: 0.625rem;
        font-family: "Open Sans";
        font-weight: 600;
        font-style: normal;
        line-height: 14px;
        text-decoration: none;
        text-transform: none;
    }
    .textStyleComponentsTableHeader {
        font-size: 0.875rem;
        font-family: "Open Sans";
        font-weight: 600;
        font-style: normal;
        line-height: 24px;
        letter-spacing: 0.17px;
        text-decoration: none;
        text-transform: none;
    }
    .textStyleComponentsMenuItem {
        font-size: 1rem;
        font-family: "Poppins";
        font-weight: 400;
        font-style: normal;
        line-height: 150%;
        letter-spacing: 0.15px;
        text-decoration: none;
        text-transform: none;
    }
    .textStyleComponentsMenuItemDense {
        font-size: 0.875rem;
        font-family: "Poppins";
        font-weight: 400;
        font-style: normal;
        line-height: 24px;
        letter-spacing: 0.17px;
        text-decoration: none;
        text-transform: none;
    }
    .textStyleComponentsListSubheader {
        font-size: 0.875rem;
        font-family: "Open Sans";
        font-weight: 600;
        font-style: normal;
        line-height: 48px;
        letter-spacing: 0.1px;
        text-decoration: none;
        text-transform: none;
    }
    .textStyleComponentsBottomNavigationActiveLabel {
        font-size: 0.875rem;
        font-family: "Open Sans";
        font-weight: 400;
        font-style: normal;
        line-height: 166%;
        letter-spacing: 0.4px;
        text-decoration: none;
        text-transform: none;
    }
    .textStyleComponentsDatePickerCurrentMonth {
        font-size: 1rem;
        font-family: "Open Sans";
        font-weight: 600;
        font-style: normal;
        line-height: 150%;
        letter-spacing: 0.15px;
        text-decoration: none;
        text-transform: none;
    }
    .textStyleLibraryHeading {
        font-size: 4rem;
        font-family: "Poppins";
        font-weight: 500;
        font-style: normal;
        line-height: 116.7%;
        letter-spacing: -1.5px;
        text-decoration: none;
        text-transform: none;
    }
    .effectStyleElevationOutlined {
        box-shadow: 0px 0px 0px 1px rgba(224,224,224,1);
    }
    .effectStyleElevation1 {
        box-shadow: 0px 1px 3px rgba(0,0,0,0.120), 0px 1px 1px rgba(0,0,0,0.140), 0px 2px 1px -1px rgba(0,0,0,0.200);
    }
    .effectStyleElevation1 {
        box-shadow: 0px 1px 3px rgba(0,0,0,0.120), 0px 1px 1px rgba(0,0,0,0.140), 0px 2px 1px -1px rgba(0,0,0,0.200);
    }
    .effectStyleElevation1 {
        box-shadow: 0px 1px 3px rgba(0,0,0,0.120), 0px 1px 1px rgba(0,0,0,0.140), 0px 2px 1px -1px rgba(0,0,0,0.200);
    }
    .effectStyleElevation2 {
        box-shadow: 0px 1px 5px rgba(0,0,0,0.120), 0px 2px 2px rgba(0,0,0,0.140), 0px 3px 1px -2px rgba(0,0,0,0.200);
    }
    .effectStyleElevation2 {
        box-shadow: 0px 1px 5px rgba(0,0,0,0.120), 0px 2px 2px rgba(0,0,0,0.140), 0px 3px 1px -2px rgba(0,0,0,0.200);
    }
    .effectStyleElevation2 {
        box-shadow: 0px 1px 5px rgba(0,0,0,0.120), 0px 2px 2px rgba(0,0,0,0.140), 0px 3px 1px -2px rgba(0,0,0,0.200);
    }
    .effectStyleElevation3 {
        box-shadow: 0px 1px 8px rgba(0,0,0,0.120), 0px 3px 4px rgba(0,0,0,0.140), 0px 3px 3px -2px rgba(0,0,0,0.200);
    }
    .effectStyleElevation3 {
        box-shadow: 0px 1px 8px rgba(0,0,0,0.120), 0px 3px 4px rgba(0,0,0,0.140), 0px 3px 3px -2px rgba(0,0,0,0.200);
    }
    .effectStyleElevation3 {
        box-shadow: 0px 1px 8px rgba(0,0,0,0.120), 0px 3px 4px rgba(0,0,0,0.140), 0px 3px 3px -2px rgba(0,0,0,0.200);
    }
    .effectStyleElevation4 {
        box-shadow: 0px 1px 10px rgba(0,0,0,0.120), 0px 4px 5px rgba(0,0,0,0.140), 0px 2px 4px -1px rgba(0,0,0,0.200);
    }
    .effectStyleElevation4 {
        box-shadow: 0px 1px 10px rgba(0,0,0,0.120), 0px 4px 5px rgba(0,0,0,0.140), 0px 2px 4px -1px rgba(0,0,0,0.200);
    }
    .effectStyleElevation4 {
        box-shadow: 0px 1px 10px rgba(0,0,0,0.120), 0px 4px 5px rgba(0,0,0,0.140), 0px 2px 4px -1px rgba(0,0,0,0.200);
    }
    .effectStyleElevation5 {
        box-shadow: 0px 1px 14px rgba(0,0,0,0.120), 0px 5px 8px rgba(0,0,0,0.140), 0px 3px 5px -1px rgba(0,0,0,0.200);
    }
    .effectStyleElevation5 {
        box-shadow: 0px 1px 14px rgba(0,0,0,0.120), 0px 5px 8px rgba(0,0,0,0.140), 0px 3px 5px -1px rgba(0,0,0,0.200);
    }
    .effectStyleElevation5 {
        box-shadow: 0px 1px 14px rgba(0,0,0,0.120), 0px 5px 8px rgba(0,0,0,0.140), 0px 3px 5px -1px rgba(0,0,0,0.200);
    }
    .effectStyleElevation6 {
        box-shadow: 0px 1px 18px rgba(0,0,0,0.120), 0px 6px 10px rgba(0,0,0,0.140), 0px 3px 5px -1px rgba(0,0,0,0.200);
    }
    .effectStyleElevation6 {
        box-shadow: 0px 1px 18px rgba(0,0,0,0.120), 0px 6px 10px rgba(0,0,0,0.140), 0px 3px 5px -1px rgba(0,0,0,0.200);
    }
    .effectStyleElevation6 {
        box-shadow: 0px 1px 18px rgba(0,0,0,0.120), 0px 6px 10px rgba(0,0,0,0.140), 0px 3px 5px -1px rgba(0,0,0,0.200);
    }
    .effectStyleElevation7 {
        box-shadow: 0px 2px 16px 1px rgba(0,0,0,0.120), 0px 7px 10px 1px rgba(0,0,0,0.140), 0px 4px 5px -2px rgba(0,0,0,0.200);
    }
    .effectStyleElevation7 {
        box-shadow: 0px 2px 16px 1px rgba(0,0,0,0.120), 0px 7px 10px 1px rgba(0,0,0,0.140), 0px 4px 5px -2px rgba(0,0,0,0.200);
    }
    .effectStyleElevation7 {
        box-shadow: 0px 2px 16px 1px rgba(0,0,0,0.120), 0px 7px 10px 1px rgba(0,0,0,0.140), 0px 4px 5px -2px rgba(0,0,0,0.200);
    }
    .effectStyleElevation8 {
        box-shadow: 0px 3px 14px 2px rgba(0,0,0,0.120), 0px 8px 10px 1px rgba(0,0,0,0.140), 0px 5px 5px -3px rgba(0,0,0,0.200);
    }
    .effectStyleElevation8 {
        box-shadow: 0px 3px 14px 2px rgba(0,0,0,0.120), 0px 8px 10px 1px rgba(0,0,0,0.140), 0px 5px 5px -3px rgba(0,0,0,0.200);
    }
    .effectStyleElevation8 {
        box-shadow: 0px 3px 14px 2px rgba(0,0,0,0.120), 0px 8px 10px 1px rgba(0,0,0,0.140), 0px 5px 5px -3px rgba(0,0,0,0.200);
    }
    .effectStyleElevation9 {
        box-shadow: 0px 3px 16px 2px rgba(0,0,0,0.120), 0px 9px 12px 1px rgba(0,0,0,0.140), 0px 5px 6px -3px rgba(0,0,0,0.200);
    }
    .effectStyleElevation9 {
        box-shadow: 0px 3px 16px 2px rgba(0,0,0,0.120), 0px 9px 12px 1px rgba(0,0,0,0.140), 0px 5px 6px -3px rgba(0,0,0,0.200);
    }
    .effectStyleElevation9 {
        box-shadow: 0px 3px 16px 2px rgba(0,0,0,0.120), 0px 9px 12px 1px rgba(0,0,0,0.140), 0px 5px 6px -3px rgba(0,0,0,0.200);
    }
    .effectStyleElevation10 {
        box-shadow: 0px 4px 18px 3px rgba(0,0,0,0.120), 0px 10px 14px 1px rgba(0,0,0,0.140), 0px 6px 6px -3px rgba(0,0,0,0.200);
    }
    .effectStyleElevation10 {
        box-shadow: 0px 4px 18px 3px rgba(0,0,0,0.120), 0px 10px 14px 1px rgba(0,0,0,0.140), 0px 6px 6px -3px rgba(0,0,0,0.200);
    }
    .effectStyleElevation10 {
        box-shadow: 0px 4px 18px 3px rgba(0,0,0,0.120), 0px 10px 14px 1px rgba(0,0,0,0.140), 0px 6px 6px -3px rgba(0,0,0,0.200);
    }
    .effectStyleElevation11 {
        box-shadow: 0px 4px 20px 3px rgba(0,0,0,0.120), 0px 11px 15px 1px rgba(0,0,0,0.140), 0px 6px 7px -4px rgba(0,0,0,0.200);
    }
    .effectStyleElevation11 {
        box-shadow: 0px 4px 20px 3px rgba(0,0,0,0.120), 0px 11px 15px 1px rgba(0,0,0,0.140), 0px 6px 7px -4px rgba(0,0,0,0.200);
    }
    .effectStyleElevation11 {
        box-shadow: 0px 4px 20px 3px rgba(0,0,0,0.120), 0px 11px 15px 1px rgba(0,0,0,0.140), 0px 6px 7px -4px rgba(0,0,0,0.200);
    }
    .effectStyleElevation12 {
        box-shadow: 0px 5px 22px 4px rgba(0,0,0,0.120), 0px 12px 17px 2px rgba(0,0,0,0.140), 0px 7px 8px -4px rgba(0,0,0,0.200);
    }
    .effectStyleElevation12 {
        box-shadow: 0px 5px 22px 4px rgba(0,0,0,0.120), 0px 12px 17px 2px rgba(0,0,0,0.140), 0px 7px 8px -4px rgba(0,0,0,0.200);
    }
    .effectStyleElevation12 {
        box-shadow: 0px 5px 22px 4px rgba(0,0,0,0.120), 0px 12px 17px 2px rgba(0,0,0,0.140), 0px 7px 8px -4px rgba(0,0,0,0.200);
    }
    .effectStyleElevation13 {
        box-shadow: 0px 5px 24px 4px rgba(0,0,0,0.120), 0px 13px 19px 2px rgba(0,0,0,0.140), 0px 7px 8px -4px rgba(0,0,0,0.200);
    }
    .effectStyleElevation13 {
        box-shadow: 0px 5px 24px 4px rgba(0,0,0,0.120), 0px 13px 19px 2px rgba(0,0,0,0.140), 0px 7px 8px -4px rgba(0,0,0,0.200);
    }
    .effectStyleElevation13 {
        box-shadow: 0px 5px 24px 4px rgba(0,0,0,0.120), 0px 13px 19px 2px rgba(0,0,0,0.140), 0px 7px 8px -4px rgba(0,0,0,0.200);
    }
    .effectStyleElevation14 {
        box-shadow: 0px 5px 26px 4px rgba(0,0,0,0.120), 0px 14px 21px 2px rgba(0,0,0,0.140), 0px 7px 9px -4px rgba(0,0,0,0.200);
    }
    .effectStyleElevation14 {
        box-shadow: 0px 5px 26px 4px rgba(0,0,0,0.120), 0px 14px 21px 2px rgba(0,0,0,0.140), 0px 7px 9px -4px rgba(0,0,0,0.200);
    }
    .effectStyleElevation14 {
        box-shadow: 0px 5px 26px 4px rgba(0,0,0,0.120), 0px 14px 21px 2px rgba(0,0,0,0.140), 0px 7px 9px -4px rgba(0,0,0,0.200);
    }
    .effectStyleElevation15 {
        box-shadow: 0px 6px 28px 5px rgba(0,0,0,0.120), 0px 15px 22px 2px rgba(0,0,0,0.140), 0px 8px 9px -5px rgba(0,0,0,0.200);
    }
    .effectStyleElevation15 {
        box-shadow: 0px 6px 28px 5px rgba(0,0,0,0.120), 0px 15px 22px 2px rgba(0,0,0,0.140), 0px 8px 9px -5px rgba(0,0,0,0.200);
    }
    .effectStyleElevation15 {
        box-shadow: 0px 6px 28px 5px rgba(0,0,0,0.120), 0px 15px 22px 2px rgba(0,0,0,0.140), 0px 8px 9px -5px rgba(0,0,0,0.200);
    }
    .effectStyleElevation16 {
        box-shadow: 0px 6px 30px 5px rgba(0,0,0,0.120), 0px 16px 24px 2px rgba(0,0,0,0.140), 0px 8px 10px -5px rgba(0,0,0,0.200);
    }
    .effectStyleElevation16 {
        box-shadow: 0px 6px 30px 5px rgba(0,0,0,0.120), 0px 16px 24px 2px rgba(0,0,0,0.140), 0px 8px 10px -5px rgba(0,0,0,0.200);
    }
    .effectStyleElevation16 {
        box-shadow: 0px 6px 30px 5px rgba(0,0,0,0.120), 0px 16px 24px 2px rgba(0,0,0,0.140), 0px 8px 10px -5px rgba(0,0,0,0.200);
    }
    .effectStyleElevation17 {
        box-shadow: 0px 6px 32px 5px rgba(0,0,0,0.120), 0px 17px 26px 2px rgba(0,0,0,0.140), 0px 8px 11px -5px rgba(0,0,0,0.200);
    }
    .effectStyleElevation17 {
        box-shadow: 0px 6px 32px 5px rgba(0,0,0,0.120), 0px 17px 26px 2px rgba(0,0,0,0.140), 0px 8px 11px -5px rgba(0,0,0,0.200);
    }
    .effectStyleElevation17 {
        box-shadow: 0px 6px 32px 5px rgba(0,0,0,0.120), 0px 17px 26px 2px rgba(0,0,0,0.140), 0px 8px 11px -5px rgba(0,0,0,0.200);
    }
    .effectStyleElevation18 {
        box-shadow: 0px 7px 34px 6px rgba(0,0,0,0.120), 0px 18px 28px 2px rgba(0,0,0,0.140), 0px 9px 11px -5px rgba(0,0,0,0.200);
    }
    .effectStyleElevation18 {
        box-shadow: 0px 7px 34px 6px rgba(0,0,0,0.120), 0px 18px 28px 2px rgba(0,0,0,0.140), 0px 9px 11px -5px rgba(0,0,0,0.200);
    }
    .effectStyleElevation18 {
        box-shadow: 0px 7px 34px 6px rgba(0,0,0,0.120), 0px 18px 28px 2px rgba(0,0,0,0.140), 0px 9px 11px -5px rgba(0,0,0,0.200);
    }
    .effectStyleElevation19 {
        box-shadow: 0px 7px 36px 6px rgba(0,0,0,0.120), 0px 19px 29px 2px rgba(0,0,0,0.140), 0px 9px 12px -6px rgba(0,0,0,0.200);
    }
    .effectStyleElevation19 {
        box-shadow: 0px 7px 36px 6px rgba(0,0,0,0.120), 0px 19px 29px 2px rgba(0,0,0,0.140), 0px 9px 12px -6px rgba(0,0,0,0.200);
    }
    .effectStyleElevation19 {
        box-shadow: 0px 7px 36px 6px rgba(0,0,0,0.120), 0px 19px 29px 2px rgba(0,0,0,0.140), 0px 9px 12px -6px rgba(0,0,0,0.200);
    }
    .effectStyleElevation20 {
        box-shadow: 0px 8px 38px 7px rgba(0,0,0,0.120), 0px 20px 31px 3px rgba(0,0,0,0.140), 0px 10px 13px -6px rgba(0,0,0,0.200);
    }
    .effectStyleElevation20 {
        box-shadow: 0px 8px 38px 7px rgba(0,0,0,0.120), 0px 20px 31px 3px rgba(0,0,0,0.140), 0px 10px 13px -6px rgba(0,0,0,0.200);
    }
    .effectStyleElevation20 {
        box-shadow: 0px 8px 38px 7px rgba(0,0,0,0.120), 0px 20px 31px 3px rgba(0,0,0,0.140), 0px 10px 13px -6px rgba(0,0,0,0.200);
    }
    .effectStyleElevation21 {
        box-shadow: 0px 8px 40px 7px rgba(0,0,0,0.120), 0px 21px 33px 3px rgba(0,0,0,0.140), 0px 10px 13px -6px rgba(0,0,0,0.200);
    }
    .effectStyleElevation21 {
        box-shadow: 0px 8px 40px 7px rgba(0,0,0,0.120), 0px 21px 33px 3px rgba(0,0,0,0.140), 0px 10px 13px -6px rgba(0,0,0,0.200);
    }
    .effectStyleElevation21 {
        box-shadow: 0px 8px 40px 7px rgba(0,0,0,0.120), 0px 21px 33px 3px rgba(0,0,0,0.140), 0px 10px 13px -6px rgba(0,0,0,0.200);
    }
    .effectStyleElevation22 {
        box-shadow: 0px 8px 42px 7px rgba(0,0,0,0.120), 0px 22px 35px 3px rgba(0,0,0,0.140), 0px 10px 14px -6px rgba(0,0,0,0.200);
    }
    .effectStyleElevation22 {
        box-shadow: 0px 8px 42px 7px rgba(0,0,0,0.120), 0px 22px 35px 3px rgba(0,0,0,0.140), 0px 10px 14px -6px rgba(0,0,0,0.200);
    }
    .effectStyleElevation22 {
        box-shadow: 0px 8px 42px 7px rgba(0,0,0,0.120), 0px 22px 35px 3px rgba(0,0,0,0.140), 0px 10px 14px -6px rgba(0,0,0,0.200);
    }
    .effectStyleElevation23 {
        box-shadow: 0px 9px 44px 8px rgba(0,0,0,0.120), 0px 23px 36px 3px rgba(0,0,0,0.140), 0px 11px 14px -7px rgba(0,0,0,0.200);
    }
    .effectStyleElevation23 {
        box-shadow: 0px 9px 44px 8px rgba(0,0,0,0.120), 0px 23px 36px 3px rgba(0,0,0,0.140), 0px 11px 14px -7px rgba(0,0,0,0.200);
    }
    .effectStyleElevation23 {
        box-shadow: 0px 9px 44px 8px rgba(0,0,0,0.120), 0px 23px 36px 3px rgba(0,0,0,0.140), 0px 11px 14px -7px rgba(0,0,0,0.200);
    }
    .effectStyleElevation24 {
        box-shadow: 0px 9px 46px 8px rgba(0,0,0,0.120), 0px 24px 38px 3px rgba(0,0,0,0.140), 0px 11px 15px -7px rgba(0,0,0,0.200);
    }
    .effectStyleElevation24 {
        box-shadow: 0px 9px 46px 8px rgba(0,0,0,0.120), 0px 24px 38px 3px rgba(0,0,0,0.140), 0px 11px 15px -7px rgba(0,0,0,0.200);
    }
    .effectStyleElevation24 {
        box-shadow: 0px 9px 46px 8px rgba(0,0,0,0.120), 0px 24px 38px 3px rgba(0,0,0,0.140), 0px 11px 15px -7px rgba(0,0,0,0.200);
    }
    