* {
  margin: 0;
  padding: 0;
  font-smooth: always;
}
@-moz-document url-prefix() {
  br {
    display: block;
    content: "";
    margin: 10px 0px 10px !important;
  }
}

.recharts-wrapper>svg>g {
  outline: none !important;
}

/* .body{
  background-color: #F7F7F7;
} */
._2iwq {
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: placeHolderShimmer;
  -webkit-animation-timing-function: linear;
  background: #f6f7f8;
  background-image: -webkit-gradient(
    linear,
    left center,
    right center,
    from(#f6f7f8),
    color-stop(0.2, #edeef1),
    color-stop(0.4, #f6f7f8),
    to(#f6f7f8)
  );
  background-image: -webkit-linear-gradient(
    left,
    #f6f7f8 0%,
    #edeef1 20%,
    #f6f7f8 40%,
    #f6f7f8 100%
  );
  background-repeat: no-repeat;
  background-size: 800px 104px;
  height: 104px;
  position: relative;
}

@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
.loader {
  position: fixed;
  width: 5%;
  height: 100vh;
  background: #fff url("./Components/assets/gifs/802.gif") no-repeat center;
  background-size: contain;
  z-index: 1000;
}

.Flex {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
.start {
  justify-content: flex-start;
}
.up {
  align-items: flex-start;
}
.Flex-row {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100;
}
.Flex-row-up {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
  width: 100;
}
.g20 {
  gap: 20px;
}
.Tag {
  background-color: yellow;
}
.pointer {
  cursor: pointer;
}

.scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(255, 94, 94, 0.3);
  background-color: #f5f5f5;
}

.scroll::-webkit-scrollbar {
  width: 2px;
  background-color: #f5f5f5;
}

.scroll::-webkit-scrollbar-thumb {
  background-color: #000000;
  border: 2px solid #555555;
}
.scroll::-webkit-scrollbar-horizontal {
  height: 2px;
  background-color: #f5f5f5;
}
.scroll {
  overflow-y: auto;
  /* overflow-x: auto; */
}

.invisble-scroll::-webkit-scrollbar {
  display: none;
}

.invisble-scroll {
  overflow-y: auto;
  overflow-x: hidden;
}

.modal {
  position: absolute;
  top: 10%;
  left: 10%;
  overflow: scroll;
  height: 100%;
  display: block;
  width: 80%;
}
