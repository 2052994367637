.name {
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 0 10px;
  background-color: white;
  color: black;
  font-weight: bold;
  border: 2px solid black;
}
