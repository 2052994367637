.custom-select-container {
    display: flex;
    flex-direction: column;
    margin: 10px 0;
    position: relative;
    z-index: 11;
}

.container-disable {
    pointer-events: none;
    opacity: 0.5;
}

.custom-select-label {
    font-size: 14px;
    margin-bottom: 4px;
    color: #333;
}

.custom-select {
    height: 50px;
    padding: 0 12px;
    font-size: 16px;
    color: #333;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
    transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    user-select: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    z-index: 1010; /* Higher z-index for the select box */
}

.custom-select:hover {
    border: 1.5px solid rgba(105, 32, 111, 1);
}

.custom-select.open {
    border: 1.5px solid rgba(105, 32, 111, 1);
}

.custom-options {
    list-style: none;
    padding: 0;
    margin: 0;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    overflow-y: auto;
    z-index: 10200; /* Higher z-index to ensure it appears above other elements */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

.custom-option {
    padding: 0.5rem;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.custom-option-selected {
    padding: 8px 12px;
    font-size: 16px;
    cursor: pointer;
    background-color: #f0e6ee;
    transition: background-color 0.2s ease-in-out;
}

.custom-option:hover {
    background-color: #00000010;
}
