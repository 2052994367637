.custom-sheet {
    box-sizing: content-box;
  }
  
  *, *:before, *:after {
    box-sizing: content-box;
  }
  
  .data-grid .data-header > div {
    width: 100%;
  }
  
  .data-grid .data-header > div:after, .data-grid .data-body .data-row:after {
    content: "";
    display: table;
    clear: both;
  }
  .data-grid-container .data-grid .cell>input {
    outline: none !important;
    border: 2px solid rgb(33, 133, 208);
    text-align: right;
    width: calc(100% - 10px);
    height: 20px !important;
    background: none;
    display: block;
}

  .value-viewer{
      width: 150px;
  }
  .data-grid .data-header > div > div {
    float: left;
    font-weight: bold;
    font-size: 12px;
    padding: 2px;
  }
  
  .data-grid .data-header > div > div.action-cell, 
  .data-grid .data-header > tr > .action-cell, 
  .data-grid .data-body .data-row > div.action-cell {
    width: 10%;
  }
  
  .data-grid .data-body .data-row > div {
    float: left;
    font-size: 12px;
  }
  
  .data-grid .data-body .data-row > .cell > input.data-editor {
    width: 100%;
    height: 100%;
  }
  
  .data-grid ul.data-body {
    list-style: none;
    margin-left: 0;
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 0;
  }
 
  .data-grid-container .data-grid{
      width: '100%';
      margin: auto;
  }

  .data-grid-container .data-grid .cell.selected {
    border: 1px double #2185d0;
    transition: none;
    box-shadow: inset 0 -100px 0 rgb(33 133 208 / 15%);
  }
  .action-cell{
    color: #999;
    font-size: 14px !important;
    background-color: #f5f5f5;
  }
  span.value-viewer{
    font-size: 14px !important;
    font-family:sans-serif;
  }
  .button-wrapper span.label {
    position: relative;
    z-index: 0;
    display: inline-block;
    width: 100%;
    background: #00bfff;
    cursor: pointer;
    color: #fff;
    padding: 10px 0;
    text-transform:uppercase;
    font-size:12px;
  }
  .button-wrapper {
    position: relative;
    width: 150px;
    text-align: center;
    margin: 20% auto;
  }
  #upload {
    display: inline-block;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 50px;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
}
  /* @import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600);

body{
  font-family: 'Open Sans', sans-serif;
  height:100%;
  text-align:center;
  position:relative;
}





 */